import React from 'react';

import {
  Blog,
  Features,
  Footer,
  Header,
  Possibility,
  Whatuitr,
} from './containers';

import Feature1 from './containers/feature1/Feature1';
import Feature2 from './containers/feature2/Feature2';
import { Brand, Cta, Navbar } from './components';
import './App.css';
import Header2 from './containers/header/Header2';

const Delete = () => {
  return (
    <div className="App">

   
        <Header2 />

    </div>
  );
};

export default Delete;
