import React from 'react';

import {
  Blog,
  Features,
  Footer,
  Header,
  Possibility,
  Whatuitr,
} from './containers';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Feature1 from './containers/feature1/Feature1';
import Feature2 from './containers/feature2/Feature2';
import { Brand, Cta, Navbar } from './components';
import './App.css';
import Home from './Home';
import Delete from './Delete';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/requestDelete" element={<Delete />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
