import React, { useState } from 'react';
import './navbar.css';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
// import logo from '../../assets/logo.svg';
import logo from '../../assets/logo.png';
//BEM mean in  Block element notifies

const Menu = () => (
  <>
    <p style={{color: 'black'}}>
      <a href="#home">      </a>
    </p>
    {/* <p style={{color: 'black'}}>
      
      <a href="https://staking-1.vercel.app" target="_blank" rel="noopener noreferrer">STAKING-DAPP</a>
    </p> */}
    {/* <p> */}
      {/* <a href="#possibility">Open AI</a> */}
      {/* <a href="#possibility">CHARTS</a> */}
    {/* </p> */}
    <p style={{color: 'white'}}>
      {/* <a href="#blog">Library</a> */}
      <a href="#wapp">Portfolio</a>
    </p>
    <p style={{color: 'white'}}>
      {/* <a href="#features">Case studies</a> */}
      <a href="#lot">Contact Us</a>
    </p>
  </>
);

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          
          {/* <img src={logo} alt="logo" width={20} height={20}/> */}
          
          <h2 style={{ color: 'white', fontWeight: 'bold' }}>ZENOVATION</h2>
        </div>
        <div className="gpt3__navbar-links_container">
          <Menu />
        </div>
      </div>
      <a href="https://www.zenovation.ai" target="_blank" rel = "noopener noreferrer">
      <div  className="gpt3__navbar-sign">
        <h3 style={{ fontWeight: 'bold' }}>ZENOVATION AI STUDIO</h3>
        {/* <p>Sign-In</p>
        <button type="button">Connect-Wallet</button> */}
      </div>
      </a>
      <div className="gpt3__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size="27"
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size="27"
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <Menu />
              {/* <div className="gpt3__navbar-menu_container-links-sign">
                <p>Sign In</p>
                <button type="button">Sign Up</button>
              </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
