import React from 'react';

import {
  Blog,
  Features,
  Footer,
  Header,
  Possibility,
  Whatuitr,
} from './containers';

import Feature1 from './containers/feature1/Feature1';
import Feature2 from './containers/feature2/Feature2';
import { Brand, Cta, Navbar } from './components';
import './App.css';

const Home = () => {
  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar />
        <Header />
      </div>
      {/* <Brand />
      <Whatuitr />
      <Features /> */}
      {/* <Possibility /> */}
      <Feature1 />
      <Feature2 />
      {/* <Cta />
      <Blog /> */}
      <Footer />
    </div>
  );
};

export default Home;
