import React from "react";
import "./footer.css";
import gpt3Logo from "../../logo.svg";
import { FaTwitter, FaTelegram, FaLinkedin, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <div style={{ backgroundColor: "black" }}>
      <h2
        style={{
          textAlign: "center",
          paddingTop: 10,
          paddingBottom: 0,
          backgroundColor: "black",
          color: "white",
          fontFamily: 'sans-serif'
        }}
      >
        CONTACT US
      </h2>
      <br />

      <div className="app-footer-main">
        {/* <h3>Get Connected</h3> */}
        {/* <p>
        We are a blockchain and app development company with 8+ years of
        experience in industry. Individual freelances also avaialble.
      </p> */}

        <div>
          <a
            href="http://t.me/@zenovation"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Telegram: @zenovation</p>
            <FaTelegram style={{ color: "white", margin: 1 }} size="27px" />
          </a>
        </div>

        <div>
          {/* <p>Our youtube channel</p> */}
          <a
            href="https://www.youtube.com/@zenovationai"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>&nbsp;&nbsp;&nbsp;Youtube</p>
            <FaYoutube style={{ color: "white", margin: 2 }} size="30px" />
          </a>
        </div>
        <div>
          {/* <p>Our youtube channel</p> */}
          <a
            href="https://www.linkedin.com/company/zenovationai"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>&nbsp;&nbsp;&nbsp;LinkedIn</p>
            <FaLinkedin style={{ color: "white", margin: 2 }} size="27px" />
          </a>
        </div>
        <p>&nbsp;&nbsp;&nbsp;Email:- info@zenovation.in</p>

        {/* <div className="gpt3__footer section__padding"> */}

        {/* <div className="gpt3__footer-heading">
        <h1 className="gradient__text">Do you want to step in to the future before others</h1>
      </div> */}

        {/* <div className="gpt3__footer-btn">
      <p>Request Early Access</p>
    </div> */}

        {/* <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
        <p>Crechterwoord K12 182 DK Alknjkcb, <br /> All Rights Reserved</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <p>Overons</p>
        <p>Social Media</p>
        <p>Counters</p>
        <p>Contact</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <p>Terms & Conditions </p>
        <p>Privacy Policy</p>
        <p>Contact</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>Crechterwoord K12 182 DK Alknjkcb</p>
        <p>085-132567</p>
        <p>info@payme.net</p>
      </div>
    </div> */}

        {/* //   <div className="gpt3__footer-copyright">
  //     <p>Social Links asdkljaslkdjalksdjaklsd alskdjalksjdlaksjd alksdjalksjdalksdj</p>
  //     <p>Social Links asdkljaslkdjalksdjaklsd alskdjalksjdlaksjd alksdjalksjdalksdj</p> 
  //     <p>Social Links asdkljaslkdjalksdjaklsd alskdjalksjdlaksjd alksdjalksjdalksdj</p>
  //   </div>
  // </div> */}
      </div>
      <h3
        style={{
          textAlign: "center",
          paddingTop: 0,
          paddingBottom: 20,
          backgroundColor: "black",
          color: "white",
          fontFamily: 'sans-serif'
        }}
      >
        ZENOVATION COMPUTING PVT LTD <br />Address: RZ 2666, Tughlakabad Extn.
        New Delhi, 110019, India.
      </h3>
    </div>
  );
};

export default Footer;
