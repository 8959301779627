import React, { useRef, useState } from "react";
import "./header.css";
import people from "../../assets/people.png";
import ai from "../../assets/ai.png";
import logo from "../../assets/logo2.png";
// import Video from '../../assets/tvid2.mp4';
import emailjs from "@emailjs/browser";
import { BarLoader } from "react-spinners";
import axios from "axios";

const Header = () => {
  const [sentMail, setSentMail] = useState(0);
  const form = useRef();

  // const sendEmail = async(e) => {

  //   let response = await axios.get("https://qiowefjqoiw.vercel.app/");

  //   console.log("============== zzzzzzzzzzzzzzz");
  //   // console.log(response.data[0].api1);

  //   console.log(response.data);

  // }

  const sendEmail = async (e) => {
    e.preventDefault();

    setSentMail(1);
    console.log(form.current);

    emailjs
      .sendForm(
        "service_fdwi9zb",
        "template_fdai0g7",
        form.current,
        "2s3nkGcfa84dDLs2p"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSentMail(2);
        },
        (error) => {
          console.log(error.text);
          setSentMail(0);
        }
      );
  };

  return (
    <div
      className="gpt3__header section__padding"
      id="home"
      style={{ width: "100%", flexDirection: "column" }}
    >
      {/* <img src={logom} alt="logo" width={'50%'} height={'50%'}/> */}
      <div
        className="gpt3__header-content"
        style={{
          width: "100%",
          // alignItems: "center",
          // justifyContent: "center",
          // alignContent: "center",
          // backgroundColor: 'red',
          flexDirection: "column",
        }}
      >
        <img src={logo} alt="logo" width={"15%"} />
        {/* <h1 className="gradient__text" style={{ fontStyle: "italic" }}>
          ZENOVATION
        </h1> */}

        <p style={{ color: "white", fontWeight: "bold", width: "60%" }}>
          An app development agency. We help you build mobile/ web-app for your business. 
          Contact us, for
          any of your app development work (Android/ iOS/ hybrid/ web/ cloud
          (AWS/ GCP)).
        </p>

        <p style={{ color: "white", fontWeight: "bold", width: "60%" }}>
          Our previous works: We are developers
          of an innovative video making app containing AI actors and also
          creators of an educational instant-doubt-solving app. 
        </p>

        {/* <p style={{ textAlign: "center", color: "white", fontWeight: "bold" }}>
          - We are experienced in Aptos, Ethereum, Solana and Polygon blockchains. Have
          worked on new (customized) wallet development, dapps, NFT marketplace,
          DeFi-platforms, Synthetix. DEFI protocols like AAVE, Compound, Curve
          etc, Skilled in writing gas-efficient smart-contracts, deployment, testing etc.
          
        </p> */}

        <div className="gpt3__header-content__input">
          <form ref={form} onSubmit={sendEmail}>
            <div>
              {/* <label>Name</label> */}
              <input type="text" name="user_name" placeholder="Your name" />
              {/* <label>Email</label> */}
              <input type="email" name="user_email" placeholder="Your email" />
            </div>
            {/* <label>Message</label> */}
            <textarea name="message" placeholder="Message" />
            {sentMail === 0 ? (
              <input type="submit" value="Message Us" />
            ) : (
              <></>
            )}

            {sentMail === 1 ? (
              <BarLoader
                lodaing
                color="white"
                width={"100%"}
                cssOverride={{ marginTop: 15 }}
              />
            ) : (
              // <p style={{ fontWeight: "bold" }}>SENDING MESSAGE ...</p>
              <></>
            )}

            {sentMail === 2 ? (
              <p style={{ fontWeight: "bold" }}>MESSAGE SENT SUCCESSFULLY</p>
            ) : (
              <></>
            )}
          </form>

          {/* <input type='email' placeholder="Your Email Address"/>
                  <input type='email' placeholder="Your Email Address"/>
                  <button type='button'>Subscribe</button> */}
        </div>

        {/* <div className='gpt3__header-content__people' >
                <img src={people} alt="people"/>
                <p style={{color: 'white'}}>100+ happy customers and counting.</p>
              </div> */}
      </div>

      <div
        className="gpt3__header-image"
        style={{
          marginTop: "10px",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <img src={ai} alt="ai"/> */}

        {/* <iframe src='https://twitter.com/i/status/1594145726099255296'
        frameborder='0'
        allow='autoplay; encrypted-media'
        allowfullscreen
        title='video'
    /> */}
        {/* <div style={{backgroundColor: 'black', width: '100%', alignContent: 'center',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                }}>
      <video width="100%" controls autostart autoPlay src={Video} type="video/mp4" />
    </div> */}
      </div>
    </div>
  );
};

export default Header;
