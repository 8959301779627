import React, { useRef, useState } from "react";
import "./header.css";
import people from "../../assets/people.png";
import ai from "../../assets/ai.png";
import logo from "../../assets/logonew.png";
// import Video from '../../assets/tvid2.mp4';
import emailjs from "@emailjs/browser";
import { BarLoader } from "react-spinners";
import axios from "axios";

const Header2 = () => {
  const [sentMail, setSentMail] = useState(0);
  const form = useRef();

  // const sendEmail = async(e) => {

  //   let response = await axios.get("https://qiowefjqoiw.vercel.app/");

  //   console.log("============== zzzzzzzzzzzzzzz");
  //   // console.log(response.data[0].api1);

  //   console.log(response.data);

  // }

  const sendEmail = async (e) => {
    e.preventDefault();

    setSentMail(1);
    console.log(form.current);

    emailjs
      .sendForm(
        "service_fdwi9zb",
        "template_fdai0g7",
        form.current,
        "2s3nkGcfa84dDLs2p"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSentMail(2);
        },
        (error) => {
          console.log(error.text);
          setSentMail(0);
        }
      );
  };

  return (
    <div
      className="gpt3__header section__padding"
      id="home"
      style={{ width: "100%", flexDirection: "column" }}
    >
      {/* <img src={logom} alt="logo" width={'50%'} height={'50%'}/> */}
      <div
        className="gpt3__header-content"
        style={{
          width: "100%",
          // alignItems: "center",
          // justifyContent: "center",
          // alignContent: "center",
          // backgroundColor: 'red',
          flexDirection: "column",
        }}
      >
        <img src={logo} alt="logo" width={"15%"} />
        {/* <h1 className="gradient__text" style={{ fontStyle: "italic" }}>
          ZENOVATION
        </h1> */}

        <p style={{ color: "white", fontWeight: "normal", width: "80%" }}>
          ZENU study app ( SSC, Railways, BankPO )
        </p>

        <p style={{ color: "white", fontWeight: "normal", width: "80%" }}>
          Kindly fill the form below and submit us. Let us know what data you
          want to be deleted or if you want to delete your complete account. Our
          executive will contact you in the next 24 hours to confirm and after
          your confirmation, your data or account will be deleted in 5 business
          days.
        </p>

        <div className="gpt3__header-content__input">
          <form ref={form} onSubmit={sendEmail}>
            <div>
              {/* <label>Name</label> */}
              <input
                type="text"
                name="user_name"
                placeholder="Your phone number"
              />
              {/* <label>Email</label> */}
              <input type="email" name="user_email" placeholder="Your email" />
            </div>
            {/* <label>Message</label> */}
            <textarea name="message" placeholder="Provide details" />
            {sentMail === 0 ? (
              <input type="submit" value="SUBMIT" />
            ) : (
              <></>
            )}

            {sentMail === 1 ? (
              <BarLoader
                lodaing
                color="white"
                width={"100%"}
                cssOverride={{ marginTop: 15 }}
              />
            ) : (
              // <p style={{ fontWeight: "bold" }}>SENDING MESSAGE ...</p>
              <></>
            )}

            {sentMail === 2 ? (
              <p style={{ fontWeight: "bold" }}>MESSAGE SENT SUCCESSFULLY</p>
            ) : (
              <></>
            )}
          </form>

          {/* <input type='email' placeholder="Your Email Address"/>
                  <input type='email' placeholder="Your Email Address"/>
                  <button type='button'>Subscribe</button> */}
        </div>

        {/* <div className='gpt3__header-content__people' >
                <img src={people} alt="people"/>
                <p style={{color: 'white'}}>100+ happy customers and counting.</p>
              </div> */}
      </div>

      <div
        className="gpt3__header-image"
        style={{
          marginTop: "10px",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <img src={ai} alt="ai"/> */}

        {/* <iframe src='https://twitter.com/i/status/1594145726099255296'
        frameborder='0'
        allow='autoplay; encrypted-media'
        allowfullscreen
        title='video'
    /> */}
        {/* <div style={{backgroundColor: 'black', width: '100%', alignContent: 'center',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                }}>
      <video width="100%" controls autostart autoPlay src={Video} type="video/mp4" />
    </div> */}
      </div>
    </div>
  );
};

export default Header2;
