import React from "react";
import "./Feature2.css";
import feature1Image from "../../assets/feature2.png";
import YouTube from "react-youtube";

const Feature2 = () => {
  let youtubeWidth = 0;

  if (window.innerWidth > 700) {
    youtubeWidth = "640";
  } else {
    youtubeWidth = window.innerWidth;
  }

  const opts = {
    height: "390",
    width: youtubeWidth,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  function onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  return (
    <div
      className="gpt3__possibility section__padding"
      id="lot"
      style={{ backgroundColor: "#3A3A3A" }}
    >
      <div className="gpt3__possibility-content">
        <h1 style={{ color: "white", fontWeight: "bold" }}>
          Our Study App
        </h1>
        {/* <h1 className="gradient__text">The possibilities are <br /> beyond your imagination</h1> */}
        <p style={{ color: "white", fontWeight: "bold", marginRight: "5%" }}>
          ZENOVATION (now ZENU) mobile app. Here students can ask doubts and get solutions. 
          Students can refer study material (notes and video lectures). 
          FREE to download app. For students preparing for GATE, IES, engineering exams and 
          SSC, BankPO and other related exams. Watch preview video at the side.
        </p>
        <h4></h4>
      </div>

      <div className="gpt3__possibility-image">
        {/* <img src={feature1Image} alt='feature'/> */}
        <YouTube videoId="CL6vcY5ZlvE" opts={opts} onReady={onReady} />
      </div>
    </div>
  );
};

export default Feature2;
