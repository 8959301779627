import React from "react";
import "./Feature1.css";
import feature1Image from "../../assets/feature1.png";
import YouTube from "react-youtube";

const Feature1 = () => {

  let youtubeWidth = 0

  if (window.innerWidth > 700) {
    youtubeWidth = "640";
  }
  else {
    youtubeWidth = window.innerWidth;
  }

  const opts = {
    height: "390",
    width: youtubeWidth,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  function onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  return (
    <div
      className="gpt3__possibility section__padding"
      id="wapp"
      style={{ backgroundColor: "#3A3A3A" }}
    >
      <div className="gpt3__possibility-image">
        <YouTube videoId="mUf7YyRq9kc" opts={opts} onReady={onReady} />
        {/* <img src={feature1Image} alt='feature'/> */}
      </div>

      <div className="gpt3__possibility-content">
        <h1 style={{ color: "white", fontWeight: "bold" }}>
          Our video making app containing AI actors
        </h1>
        {/* <h1 className="gradient__text">The possibilities are <br /> beyond your imagination</h1> */}
        <p style={{ color: "white", fontWeight: 'bold' }}>
        ZENOVATION's own web-app (website) which helps users to create 
        customised videos. User can select a real-looking AI-human-actor, provide a text-script for that AI-actor, add music, logo, images, shapes, background and more and create video content in few minutes easily 
        in which the AI human actor speaks the text-script that was provided.
        Click play button to watch preview.
        </p>
        <h4></h4>
      </div>
    </div>
  );
};

export default Feature1;
